<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body bg-primary rounded">
            <h1 class="card-title">{{ qnt_users }} Usuários</h1>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body bg-success rounded">
            <h1 class="card-title">{{ qnt_stores }} Lojas</h1>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body bg-warning rounded">
            <h1 class="card-title">{{ qnt_categories }} Categorias</h1>
          </div>
        </div>
      </div>
      <!-- <div class="col">
        <div class="card">
          <div class="card-body bg-danger rounded">
            <h1 class="card-title">{{qnt_companies}} Empresas</h1>
          </div>        
        </div>        
      </div> -->
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      qnt_users: 0,
      qnt_categories: 0,
      qnt_stores: 0,
      // qnt_companies: 0
    };
  },
  mounted() {
    ApiService.get("users/count").then(({ data }) => {
      this.qnt_users = data;
    });

    ApiService.get("stores/count").then(({ data }) => {
      this.qnt_stores = data;
    });

    ApiService.get("categories/count").then(({ data }) => {
      this.qnt_categories = data;
    });

    // ApiService.get("companies/getQnt")
    //   .then(({ data }) => {
    //     this.qnt_companies = data.qnt;
    //   });

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {},
};
</script>

<style></style>
